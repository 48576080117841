import { URLS } from "../strings/urls";

const YashaId = "yasha"
const MichaylaId = "michayla"
const MiaId = "mia"
const FionaId = "fiona"
const StaceyId = "stacey"
const HudsonId = "hudson"


const YashaHeadshot = "/physio/team/headshot_yasha.jpg";
const MichaylaHeadshot = "/physio/team/headshot_michayla.jpg";
const MiaHeadshot = "/physio/team/headshot_mia.jpg"
const FionaHeadshot = "/physio/team/headshot_fiona.jpg"
const StaceyHeadshot = "/physio/team/headshot_stacey.jpg"
const HudsonHeadshot = "/physio/team/headshot_hudson.jpg"

const EmployeeProfiles = [
    {
        id: YashaId,
        name: "Yasha Gurevich",
        title: "Principal Physiotherapist",
        description: "Yasha completed his Bachelor of Applied Science and Masters of Physiotherapy at La Trobe Bendigo. " +
            "Yasha’s clinical interest involve management of complex musculoskeletal conditions, sports physiotherapy and injury prevention. " +
            "Yasha has been involved in a variety of different sports settings including soccer, basketball and is currently the head physiotherapist at AJAX football club. " +
            "A passion for ongoing education and knowledge translation Yasha has delivered presentations on injury prevention to variety of different sports clubs and Lectures at La Trobe university on musculoskeletal physiotherapy. " +
            "With a strong focus on being patient centred, Yasha works closely with his patients to achieve their goals.",
        image: YashaHeadshot,
        link: URLS.PROFILE_YASHA,
    },
    {
        id: MichaylaId,
        name: "Michayla Bancroft",
        title: "Physiotherapist/Physiotherapy-led Instructor",
        description: "Michayla Graduated from Bond University with a Doctor of Physiotherapy in 2022, after having completed a Bachelor of Exercise Science from LaTrobe University in 2019. " +
            "Michayla decided to Pursue a career in Physiotherapy after being treated as a patient and experiencing firsthand the impact a good Physio can make." +
            "Michayla has a keen interest in chronic conditions and the rehabilitation of sporting injuries, especially of the shoulder. When Michayla isn’t physioing, she is spending time with her beloved dog Alfie, chasing good coffee and barracking for Carlton.",
        image: MichaylaHeadshot,
        link: URLS.PROFILE_MICHAYLA,
    },
    {
        id: MiaId,
        name: "Mia Brayer",
        title: "Physiotherapist/Physiotherapy-led Instructor",
        description: "Mia graduated from La Trade University with a Bachelor of Applied Science and Masters of Physiotherapy Practice degree. Mia is passionate about providing a holistic approach towards helping people with pain and injuries return to what they love doing and achieve their goals. She has a keen interest in pain management, injury rehabilitation and prevention and has experience in treating a variety of conditions affecting clients of all ages and from all walks of life. Outside of work, Mia enjoys spending time outdoors, watching AFL and baking various goodies for her friends and family.",
        image: MiaHeadshot,
        link: URLS.PROFILE_MIA,
    },
    {
        id: StaceyId,
        name: "Stacey Kipouridis",
        title: "Physiotherapist/Physiotherapy-led Instructor",
        description: "Stacey completed her Bachelor of Applied Science and Masters of Physiotherapy at La Trobe University. Stacey has a keen interest in dance, sports and women’s health physiotherapy. She works in a variety of physiotherapy fields including working with performing artists to provide acute care and injury prevention programs.  She has also worked with  professional soccer players assisting in the rehabilitation of Melbourne Victory players following surgery. Stacey has completed her Women’s Health training with APA and enjoys working with women to support them through their lifespan. Stacey has completed all the APPI Matwork and Equipment  courses and is an APPI pilates course presenter. She loves being able to incorporate pilates principles into her clinical practice. She strongly believes in treating her clients with a holistic approach, taking the time to develop personalised goals and self management skills to maintain injury prevention.",
        image: StaceyHeadshot,
        link: URLS.PROFILE_STACEY,
    },
    {
        id: FionaId,
        name: "Fiona Bruns",
        title: "Reception",
        image: FionaHeadshot,
        link: URLS.PROFILE_FIONA,
    },
    {
        id: HudsonId,
        name: "Hudson Augustini",
        title: "Reception",
        image: HudsonHeadshot,
        link: URLS.PROFILE_HUDSON,
    }
]

export {
    EmployeeProfiles,
}