const URLS = {
    /*CORE*/
    ROOT: "/",
    ERROR: "/404",
    ABOUT: "/about",
    CONTACT: "/contact",
    BOOK: "/book",

    /*EMPLOYEE PROFILE*/
    PROFILE: "/profiles",
    PROFILE_YASHA: "/profiles/yasha",
    PROFILE_MICHAYLA: "/profiles/michayla",
    PROFILE_MIA: "/profiles/mia",
    PROFILE_FIONA: "/profiles/fiona",
    PROFILE_STACEY: "/profiles/stacey",
    PROFILE_HUDSON: "/profiles/hudson",

    /*SERVICES*/
    PHYSIOTHERAPY: "/services/physiotherapy",
    PHYSIO_LEAD_REHAB: "/services/physio-led-rehab",
    PROFESSIONAL_MENTORING: "/services/professional-mentoring",

    /* EXTERNAL */
    CLINIKO: "https://movewell-physiotherapy-melbourne.au2.cliniko.com/bookings",
}

export {
    URLS
}
